<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link href="https://jjcargothailand.com/" class="brand-logo" >
        <!-- <vuexy-logo /> -->
        <b-img
          class="d-none d-sm-block"
          :src="require('@/assets/jjcargo/jjcargo-logo.png')"
          fluid
          width="50px"
          height="30px"
        >
        </b-img>
        <h2 class="brand-text text-primary ml-1 mt-1 d-none d-sm-block">
          JJCargo
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <!-- <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-0">
          <b-img
            style="width:100%"
            fluid
            :src="require('@/assets/taobao2you+no1.png')"
            alt="Login taobao"
          />
        </div>
      </b-col> -->
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="12"
          md="6"
          lg="5"
          class="px-xl-2 mx-auto"
        >
          <b-img
            center
            class="d-block d-sm-none mt-n3 mb-3"
            style="hieght:50%"
            fluid
            :src="require('@/assets/jjcargo/jjcargo-logo.png')"
            alt="Login taobao"
          />
          <b-card-title
            class="mb-1 font-weight-bold text-center d-none d-sm-block"
            title-tag="h2"
          >
          <!-- <b-img
            class="d-block d-sm-none "
            style="width:100%"
            fluid
            :src="require('@/assets/jjcargo/jjcargo-logo.png')"
            alt="Login taobao"
          /> -->
            เข้าสู่ระบบ
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="loginButton"
            >
              <!-- email -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="user"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{ name: 'forgot-password'}">
                    <small>ลืมรหัสผ่าน?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="pass"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loadButton == false"
                @click="loginButton()"
              >
                <b-spinner v-if="loadButton == false" small />
                เข้าสู่ระบบ
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { taobaoService_dotnet   } from '@/views/global'
import axios from 'axios'
import { token_api, header_token } from '@/views/token'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox,
  BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BCard, BSpinner
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { dashboardPermission } from '@/navigation/vertical/dashboard.js'
import {jjCargoService_dotnet} from '@/views/global.js'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props:{
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BCard,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,

      //
      profile: [],
      user: '',
      pass: '',

      cusID: null,

      loadButton: true,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    if (token != null && token != '') {
      this.loginByToken(token)
    }
  },

  methods: {
    async loginButton(){
      this.loadButton = await false
      var res = []
      var sToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ1NDMxOTM3LCJleHAiOjE2NDU0MzI1Mzd9.GAO0TlWsHDaP_KImlaG6MomhnomjHj5xTWMkrfoNUmw"
      var rToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ1NDMxOTM3LCJleHAiOjE2NDU0MzI1Mzd9.FoUDDNh6hBlWHtHKsagslOFU20v9aYVAhDX9SqKAajE"
      // var abi = [{action: "read", subject: "dashboard"}]
      this.profile = []
      let response = await axios.post(
        jjCargoService_dotnet + 'Customer/login',
        {
          'username': this.user,
          'password': this.pass
        },
        { headers: header_token}
      )
      res = response.data
      this.$refs.loginForm.validate().then( async success => {
        if( response.data.message == 'success' ) {
          if (success) {

            await this.profile.push({
              'data': response.data,
              'role': 'admin',
              'ability': [
                {action: "manage", subject: "normal"},
                {action: "manage", subject: "Auth"},
                ]
            })
            useJwt.setToken(sToken)
            useJwt.setRefreshToken(rToken)
            localStorage.setItem('userData', JSON.stringify(this.profile[0]))
            localStorage.setItem('cusId', JSON.stringify(this.profile[0].data.customerInfo.customerID))
            this.$ability.update(this.profile[0].ability)


            this.$router.replace(getHomeRouteForLoggedInUser(this.profile[0].role))


            this.loadButton = true

          }
        } else {
          this.$swal({
            position: 'top',
            title: 'ล็อคอินไม่สำเร็จ !',
            text: response.data.message + ' !',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
              text: 'text-danger'
            },
            buttonsStyling: false,
          })
          this.loadButton = true
        }
      })
    },

    async loginByToken(token) {
      var res = []
      var sToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ1NDMxOTM3LCJleHAiOjE2NDU0MzI1Mzd9.GAO0TlWsHDaP_KImlaG6MomhnomjHj5xTWMkrfoNUmw"
      var rToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQ1NDMxOTM3LCJleHAiOjE2NDU0MzI1Mzd9.FoUDDNh6hBlWHtHKsagslOFU20v9aYVAhDX9SqKAajE"
      this.profile = []
      let response = await axios.post(
        jjCargoService_dotnet + 'Customer/login-by-token',
        {
          'token': token,
        },
        { headers: header_token}
      )
      res = response.data
      if ( response.data.message == 'success' ) {
        await this.profile.push({
          'data': response.data,
          'role': 'admin',
          'ability': [
            {action: "manage", subject: "normal"},
            {action: "manage", subject: "Auth"},
            ]
        })
        useJwt.setToken(sToken)
        useJwt.setRefreshToken(rToken)
        localStorage.setItem('userData', JSON.stringify(this.profile[0]))
        localStorage.setItem('cusId', JSON.stringify(this.profile[0].data.customerInfo.customerID))
        this.$ability.update(this.profile[0].ability)
        this.$router.replace(getHomeRouteForLoggedInUser(this.profile[0].role))
      } else {
        this.$swal({
          position: 'top',
          title: 'ล็อคอินไม่สำเร็จ !',
          text: response.data.message + ' !',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
            text: 'text-danger'
          },
          buttonsStyling: false,
        })
        this.loadButton = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>

.fb-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 13px;
  width: 100%;
  border-radius: 3px;
  background-color: #4267b2;
  color: #fff;
}

</style>
