export default [{
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
        resource: localStorage.getItem("limit_f"),
        action: 'read',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
        disabled: (localStorage.getItem("cusId") == 1 ? false : true),
      },

    ],
  }]



